import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import "./Trips.css";

const Trips = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    destination: "",
    departureDate: "",
    returnDate: "",
    isFlexible: "no",
    flexibleDates: "",
    adults: 1,
    children: 0,
    packageType: "",
    budget: "",
    message: "",
  });

  const [status, setStatus] = useState("");
  const [isFlexible, setIsFlexible] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "isFlexible") {
      setIsFlexible(value === "yes");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_3pbeito",
        "template_evi465i453",
        {
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          destination: formData.destination,
          departureDate: formData.departureDate,
          returnDate: formData.returnDate,
          isFlexible: formData.isFlexible,
          flexibleDates: formData.flexibleDates,
          adults: formData.adults,
          children: formData.children,
          packageType: formData.packageType,
          budget: formData.budget,
          message: formData.message,
        },
        "Cc9GCMWakFKr783_5"
      )
      .then(
        (result) => {
          console.log("Email sent successfully:", result.text);
          setStatus("Your trip inquiry has been sent successfully!");
          setFormData({
            name: "",
            email: "",
            phone: "",
            destination: "",
            departureDate: "",
            returnDate: "",
            isFlexible: "no",
            flexibleDates: "",
            adults: 1,
            children: 0,
            packageType: "",
            budget: "",
            message: "",
          });
          setIsFlexible(false);
        },
        (error) => {
          console.error("Failed to send email:", error.text);
          setStatus("Failed to send your inquiry. Please try again.");
        }
      );
  };

  return (
    <div className="trips-contact-form-container">
      <h2>Plan Your Trip with Scotland Safaris</h2>
      <form onSubmit={handleSubmit}>
        {/* Personal Information Section */}
        <div className="trips-form-section">
          <h3>Personal Information</h3>
          <div className="trips-form-group">
            <label htmlFor="name">Full Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="trips-form-group">
            <label htmlFor="email">Email Address:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="trips-form-group">
            <label htmlFor="phone">Phone Number:</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        {/* Travel Details Section */}
        <div className="trips-form-section">
          <h3>Travel Details</h3>
          <div className="trips-form-group">
            <label htmlFor="destination">Destination(s):</label>
            <input
              type="text"
              id="destination"
              name="destination"
              value={formData.destination}
              onChange={handleChange}
              required
            />
          </div>
          <div className="trips-form-group">
            <label htmlFor="departureDate">Departure Date:</label>
            <input
              type="date"
              id="departureDate"
              name="departureDate"
              value={formData.departureDate}
              onChange={handleChange}
              required
            />
          </div>
          <div className="trips-form-group">
            <label htmlFor="returnDate">Return Date:</label>
            <input
              type="date"
              id="returnDate"
              name="returnDate"
              value={formData.returnDate}
              onChange={handleChange}
              required
            />
          </div>
          <div className="trips-form-group">
            <label>Are your dates flexible?</label>
            <div className="trips-radio-group">
              <label>
                <input
                  type="radio"
                  name="isFlexible"
                  value="yes"
                  checked={formData.isFlexible === "yes"}
                  onChange={handleChange}
                />
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  name="isFlexible"
                  value="no"
                  checked={formData.isFlexible === "no"}
                  onChange={handleChange}
                />
                No
              </label>
            </div>
            {isFlexible && (
              <div className="trips-form-group">
                <label htmlFor="flexibleDates">If yes, please specify:</label>
                <input
                  type="text"
                  id="flexibleDates"
                  name="flexibleDates"
                  value={formData.flexibleDates}
                  onChange={handleChange}
                  placeholder="Specify flexible dates"
                />
              </div>
            )}
          </div>
          <div className="trips-form-group">
            <label>Number of Travelers:</label>
            <div className="trips-number-group">
              <div>
                <label htmlFor="adults">Adults:</label>
                <input
                  type="number"
                  id="adults"
                  name="adults"
                  min="1"
                  value={formData.adults}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label htmlFor="children">Children (under 12):</label>
                <input
                  type="number"
                  id="children"
                  name="children"
                  min="0"
                  value={formData.children}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
          </div>
          <div className="trips-form-group">
            <label>Travel Package Type:</label>
            <div className="trips-radio-group">
              <label>
                <input
                  type="radio"
                  name="packageType"
                  value="group-tour"
                  checked={formData.packageType === "group-tour"}
                  onChange={handleChange}
                  required
                />
                Group Tour
              </label>
              <label>
                <input
                  type="radio"
                  name="packageType"
                  value="private-tour"
                  checked={formData.packageType === "private-tour"}
                  onChange={handleChange}
                />
                Private Tour
              </label>
              <label>
                <input
                  type="radio"
                  name="packageType"
                  value="custom-itinerary"
                  checked={formData.packageType === "custom-itinerary"}
                  onChange={handleChange}
                />
                Customized Itinerary
              </label>
              <label>
                <input
                  type="radio"
                  name="packageType"
                  value="self-guided"
                  checked={formData.packageType === "self-guided"}
                  onChange={handleChange}
                />
                Self-guided
              </label>
            </div>
          </div>
          <div className="trips-form-group">
            <label>Estimated Budget per Person:</label>
            <div className="trips-radio-group">
              <label>
                <input
                  type="radio"
                  name="budget"
                  value="under-500"
                  checked={formData.budget === "under-500"}
                  onChange={handleChange}
                  required
                />
                Under £500
              </label>
              <label>
                <input
                  type="radio"
                  name="budget"
                  value="500-1000"
                  checked={formData.budget === "500-1000"}
                  onChange={handleChange}
                />
                £500 - £1,000
              </label>
              <label>
                <input
                  type="radio"
                  name="budget"
                  value="1000-2000"
                  checked={formData.budget === "1000-2000"}
                  onChange={handleChange}
                />
                £1,000 - £2,000
              </label>
              <label>
                <input
                  type="radio"
                  name="budget"
                  value="over-2000"
                  checked={formData.budget === "over-2000"}
                  onChange={handleChange}
                />
                Over £2,000
              </label>
            </div>
          </div>
        </div>

        {/* Special Requests / Message Section */}
        <div className="trips-form-section">
          <h3>Additional Information</h3>
          <div className="trips-form-group">
            <label htmlFor="message">
              Special Requests or Additional Information:
            </label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Any special requests or additional details?"
            />
          </div>
        </div>

        {/* Submit Button */}
        <button type="submit">Plan My Trip</button>
      </form>
      {status && <p className="trips-status-message">{status}</p>}
    </div>
  );
};

export default Trips;
