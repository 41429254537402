import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import emailjs from "@emailjs/browser";

emailjs.init("Cc9GCMWakFKr783_5");

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<App />);
